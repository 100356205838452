import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import React from "react";
import { AppSidebarNav } from "./AppSideBarNav";
import navigation from '../_nav'
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";

const AppSideBar = () => {
    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)
    return (
        <CSidebar
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible })
            }}
        >
            <CSidebarBrand className="" to="/" >
                <img src="/assets/favicon.ico" alt="" style={{
                    width: '50px',
                    marginLeft: '-28px'
                }} className="p-2" />
                <span className="fs-5">Up Life</span>
            </CSidebarBrand>

            <CSidebarNav>
                <SimpleBar><AppSidebarNav items={navigation} /></SimpleBar>
            </CSidebarNav>
        </CSidebar>
    );
};

export default AppSideBar;
