import { CButton, CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import React from 'react'
import { FaRegUser } from "react-icons/fa";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const AppHeaderDropDown = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate()

    const handleLogOut = async () => {
        try {
            const response = await axiosPrivate.get(`auth/admin/signout`)
            if (response.data.status === true) {
                localStorage.removeItem('authToken')
                localStorage.removeItem('refreshToken')
                navigate('/')
            } else {
                toast.error(response.data.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored"
                })
            }
        } catch (error) {
            let errorMessage = "An error occurred while logging out";
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored"
            });
        }
    }
    return (
        <CDropdown variant="nav-item">
            <CDropdownToggle placement="bottom-end" caret={false}>
                <FaRegUser size={25} />
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">
                <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
                <CDropdownItem>
                    <CButton onClick={() => handleLogOut()} style={{
                        width: '100%',
                        background: 'none',
                        color: 'black',
                        border: 'none',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        outline: 'none',
                    }}>Log Out</CButton>
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    )
}

export default AppHeaderDropDown
