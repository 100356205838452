import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const RequireAuth = () => {
  const accessToken = localStorage.getItem('authToken');

  return accessToken ? <Outlet /> : <Navigate to="/" />;
};

export default RequireAuth;
