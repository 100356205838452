import { useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refreshToken = useRefreshToken();
    const authToken = localStorage.getItem("authToken");

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = authToken
                }
                return config
            }, (error) => Promise.reject(error)

        )

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 500 && !prevRequest?.sent) {
                    prevRequest.sent = true;

                    const newAccessToken = await refreshToken()
                    prevRequest.headers['Authorization'] = newAccessToken
                    return axiosPrivate(prevRequest)
                }
                return Promise.reject(error)
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept)
            axiosPrivate.interceptors.response.eject(responseIntercept)
        }
    }, [authToken, refreshToken])

    return axiosPrivate
}

export default useAxiosPrivate