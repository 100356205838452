import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDynamicTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const setTitle = () => {
      let title = "Uplife Admin Panel";

      if (location.pathname === "/master/customers") {
        title = "Uplife - Customers";
      } else if (location.pathname === "/orders/orders-list") {
        title = "Uplife - Orders-List";
      } else if (location.pathname === "/master/blogs") {
        title = "Uplife - Blogs";
      } else if (location.pathname === "/orders/cancellation-requests") {
        title = "Uplife - Cancellation-Requests";
      } else if (location.pathname === "/dashboard") {
        title = "Uplife - Dashboard";
      }
      document.title = title;
    };

    setTitle();
  }, [location.pathname]);
};

export default useDynamicTitle;
