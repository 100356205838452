import Blogs from "./screens/blogs"
import Customers from "./screens/customers"
import Dashboard from "./screens/dashboard"
import Orders from './screens/orders'

const routes = [
    { path: '/', exact: true, name: 'Home' },
    { path: '/dashboard', name: 'Dashboard', element: Dashboard },
    { path: '/master', name: 'Master', exact: true },
    { path: '/master/customers', name: 'Customers', element: Customers },
    { path: '/master/blogs', name: 'Blogs', element: Blogs },
    { path: '/orders', name: 'Orders', exact: true },
    { path: '/orders/orders-list', name: 'Orders List', element: Customers }, 
    { path: '/orders/cancellation-requests', name: 'Cancellation Requests', element: Customers },
]

export default routes