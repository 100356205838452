import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CSpinner,
} from "@coreui/react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IoSearchSharp } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [inputText, setInputText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [numberValue, setNumberValue] = useState(1);
  const [recordsValue, setRecordsValue] = useState("10");
  const axiosPrivate = useAxiosPrivate();
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "#",
      selector: (row, index) => (numberValue - 1) * recordsValue + index + 1,
      width: "50px",
    },
    {
      name: "FULL NAME",
      cell: (row) => (
        <div className="fs-7">{row.full_name ? row.full_name : "--------"}</div>
      ),
      width: "200px",
    },
    {
      name: "EMAIL ID",
      cell: (row) => (
        <div className="fs-7">{row.email ? row.email : "--------"}</div>
      ),
      width: "200px",
    },
    {
      name: "JOINED ON",
      selector: (row) => <div className="fs-7">{row.joined_on}</div>,
    },
    {
      name: "MOBILE NO.",
      cell: (row) => (
        <div className="fs-7">{row.mobile ? row.mobile : "--------"}</div>
      ),
    },
    {
      name: "TOTAL ORDERS",
      cell: (row) => (
        <div className="fs-7">
          {row.total_orders} {row.total_orders > 1 ? "Orders" : "Order"}
        </div>
      ),
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleChange = (e) => {
    setInputText(e.target.value);
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? format(startDate, "yyyy-MM-dd")
        : "";
      const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : "";

      const response = await axiosPrivate.get(
        `customers/list?start_date=${formattedStartDate}&end_date=${formattedEndDate}&search=${inputText}&page=1&limit=${recordsValue}`
      );

      if (response?.data?.status === true) {
        setCustomers(response?.data?.data?.list);
        setTotalPages(response?.data?.data?.pagination?.no_of_pages);
      }
    } catch (error) {
      toast.error("No customer records found", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });

      let errorMessage = "An error occurred while fetching customers list.";
      if (error?.response && error?.response?.data) {
        errorMessage = error?.response?.data?.message;
      }
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const getCustomersList = async () => {
    try {
      const response = await axiosPrivate.get(
        `customers/list?start_date=&end_date=&page=1&search=&limit=10`
      );
      if (response?.data?.status === true) {
        setCustomers(response?.data?.data?.list);
        setTotalPages(response?.data?.data?.pagination?.no_of_pages);
      } else {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching customers list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleStartDateChange = (date) => {
    if (date) {
      setStartDate(date);
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      setEndDate(date);
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handlePrevClick = () => {
    const prevPage = Math.max(numberValue - 1, 1);
    setNumberValue(prevPage);
    pagination(prevPage, recordsValue);
  };

  const handleNextClick = async () => {
    const nextPage = numberValue + 1;
    if (nextPage <= totalPages) {
      setNumberValue(nextPage);
      pagination(nextPage, parseInt(recordsValue, 10));
    } else {
      toast.error("Already on the last page", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const pagination = async (pageNumber, limit) => {
    try {
      let apiEndpoint = "customers/list";
      const queryParams = {
        page: pageNumber,
        search: inputText,
        limit: limit,
      };

      if (startDate) {
        queryParams.start_date = format(startDate, "yyyy-MM-dd");
      }

      if (endDate) {
        queryParams.end_date = format(endDate, "yyyy-MM-dd");
      }

      const response = await axiosPrivate.get(apiEndpoint, {
        params: queryParams,
      });

      if (response?.data?.status === true) {
        setCustomers(response?.data?.data?.list);
        setTotalPages(response?.data?.data?.pagination?.no_of_pages);
      } else {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching customers list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    getCustomersList();
  }, []);

  return (
    <>
      <CContainer>
        <CCard>
          <CCardHeader>
            <CCardTitle className="fs-6">CUSTOMERS LIST</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleFilter}>
              <CRow xs={{ gutter: 3 }}>
                <CCol sm={12} md={2}>
                  <CFormSelect
                    name="recordsValue"
                    className="form-control"
                    value={recordsValue}
                    onChange={(e) => setRecordsValue(e.target.value || 10)}
                    options={[
                      { label: "10 records", value: "10" },
                      { label: "20 records", value: "20" },
                      { label: "40 records", value: "40" },
                      { label: "60 records", value: "60" },
                      { label: "80 records", value: "80" },
                      { label: "100 records", value: "100" },
                    ]}
                  />
                </CCol>
                <CCol sm={12} md={3}>
                  <CFormInput
                    placeholder="Search by name, email or phone number"
                    prefix={<IoSearchSharp />}
                    onChange={handleChange}
                    className="form-control"
                  />
                </CCol>

                <CCol sm={12} md={2}>
                  <DatePicker
                    onChange={handleStartDateChange}
                    selected={startDate}
                    placeholderText="Start Date"
                    className="form-control"
                  />
                </CCol>

                <CCol sm={12} md={2}>
                  <DatePicker
                    onChange={handleEndDateChange}
                    selected={endDate}
                    placeholderText="End Date"
                    className="form-control"
                  />
                </CCol>

                <CCol sm={12} md={3}>
                  <CButton className="w-100" type="submit" color="secondary">
                    {loading ? (
                      <span className="d-flex align-items-center justify-content-center gap-2">
                        <CSpinner size="sm" /> {" Loading..."}
                      </span>
                    ) : (
                      <span>
                        <FaArrowRight
                          size={15}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        SHOW
                      </span>
                    )}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>

            <div className="d-flex justify-content-end mt-4">
              <div>
                <CButton
                  disabled={numberValue === 1}
                  onClick={handlePrevClick}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
                </CButton>
              </div>

              <div className="border rounded text-center p-1">
                Page No.{numberValue}
              </div>

              <div>
                <CButton
                  onClick={handleNextClick}
                  disabled={numberValue >= totalPages}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  NEXT
                  <FaArrowRight style={{ marginLeft: "5px" }} />
                </CButton>
              </div>
            </div>

            <DataTable
              columns={updatedColumns}
              data={customers}
              className="mt-3"
              highlightOnHover
            />
          </CCardBody>
          <CCardFooter>
            <div className="d-flex justify-content-end mt-1">
              <div>
                <CButton
                  disabled={numberValue === 1}
                  onClick={handlePrevClick}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
                </CButton>
              </div>

              <div className="border rounded text-center p-1">
                Page No.{numberValue}
              </div>

              <div>
                <CButton
                  onClick={handleNextClick}
                  disabled={numberValue >= totalPages}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  NEXT
                  <FaArrowRight style={{ marginLeft: "5px" }} />
                </CButton>
              </div>
            </div>
          </CCardFooter>
        </CCard>
      </CContainer>
    </>
  );
};

export default Customers;
