import { useNavigate } from "react-router-dom";
import axios from "../api/axios";

const useRefreshToken = () => {
    const refreshTokenFromLocal = localStorage.getItem("refreshToken");
    const navigate = useNavigate()

    const refreshToken = async () => {
        try {
            const headers = {
                Authorization: refreshTokenFromLocal,
            }

            const refresh_token = {
                refresh_token: refreshTokenFromLocal
            }
            const response = await axios.post(`admin/refresh`, refresh_token, {
                headers
            });

            let newAccessToken;

            if (response.data.status === true) {
                newAccessToken = response.data.data.jwt
                localStorage.removeItem("authToken");
                localStorage.setItem("authToken", newAccessToken);
            }
            else if (response.data.status === false) {
                localStorage.removeItem("authToken");
                localStorage.removeItem("refreshToken")
                navigate('/');
            }
            return newAccessToken
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    return refreshToken
}

export default useRefreshToken
