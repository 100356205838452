import React, { useEffect, useRef, useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { toast } from "react-toastify";
import axios from "../api/axios";

const Login = () => {
  const [logInInputData, setLogInInputData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogInInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`auth/admin/signin`, logInInputData);
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        const accessToken = response?.data?.data?.jwt;
        const refreshToken = response?.data?.data?.refresh_token;

        localStorage.setItem("authToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        toast.error(error?.response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard
                className="p-4"
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                }}
              >
                <CCardBody>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <img src="/assets/icon-2.jpg" alt="icon" />
                  </div>
                  <CForm onSubmit={handleSubmit}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <h2>Login</h2>
                      <p className="text-medium-emphasis">
                        Sign In to your account
                      </p>
                    </div>
                    <CInputGroup className="mb-3 mt-4">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="username"
                        autoComplete="username"
                        name="username"
                        ref={userRef}
                        value={logInInputData.username}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4 mt-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        value={logInInputData.password}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CButton className="mt-4 w-100" type="submit">
                      {loading ? (
                        <span className="d-flex align-items-center justify-content-center gap-2">
                          <CSpinner size="sm" />
                          {" Loading..."}
                        </span>
                      ) : (
                        "Login"
                      )}
                    </CButton>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
