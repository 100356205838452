import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './scss/style.scss'
import useDynamicTitle from './hooks/useDynamicTitle';

function App() {
  useDynamicTitle()
  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer position="top-right" autoClose={3000} theme="colored" pauseOnHover={false} />
    </div>
  );
}

export default App;
