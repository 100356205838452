import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CSpinner,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import OrderDetailsModal from "../../components/OrderDetailsModal";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const ApprovedOCReq = () => {
  const axiosPrivate = useAxiosPrivate();
  const [cancellationRequests, setCancellationRequests] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [refundData, setRefundData] = useState({
    status: "",
    request_id: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div className="fs-7">{index + 1}</div>,
      width: "50px",
    },
    {
      name: "INVOICE NO.",
      cell: (row) => (
        <a
          className="fs-7 text-info"
          style={{ cursor: "pointer" }}
          onClick={() => onViewDetails(row)}
        >
          {row.invoice_no}
        </a>
      ),
      maxWidth: "150px",
    },
    {
      name: "CUSTOMER NAME",
      cell: (row) => <div className="fs-7">{row.customer_name}</div>,
      maxWidth: "160px",
    },
    {
      name: "PHONE",
      cell: (row) => <div className="fs-7">{row.customer_contact}</div>,
      maxWidth: "200px",
    },
    {
      name: "REASON",
      cell: (row) => <div className="fs-7">{row.reason}</div>,
      maxWidth: "200px",
    },
    {
      name: "PAYMENT MODE",
      cell: (row) => (
        <div className="fs-7 text-warning">
          <CBadge className="p-2" color="warning">
            {row.payment_mode}
          </CBadge>
        </div>
      ),
      minWidth: "140px",
    },
    {
      name: "ACTIONS",
      right: true,
      cell: (row) => (
        <>
          {row.refund_id !== null ? (
            <CBadge className="p-2" color="secondary">
              {`Refund_id: ${row?.refund_id}`}
            </CBadge>
          ) : row.payment_mode === "ONLINE" ? (
            <CButton
              size="sm"
              color="warning"
              onClick={() => handleOpenRefundModal(row)}
            >
              Refund
            </CButton>
          ) : (
            <CBadge className="p-2" color="success">
              {row.status}
            </CBadge>
          )}
        </>
      ),
      maxWidth: "200px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const getOrdersCancellationList = async () => {
    try {
      const response = await axiosPrivate.get(
        `admin/orders/cancellation-requests?type=APPROVED`
      );

      if (response?.data?.status === true) {
        setCancellationRequests(response?.data?.data?.requests);
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        console.log(error?.response?.data?.data?.message);
      } else {
        let errorMessage =
          "An error occurred while fetching order cancellation request list.";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const onViewDetails = (row) => {
    setIsDialogOpen(true);
    setOrderId(row?.order_id);
  };

  const handleOpenRefundModal = (data) => {
    setOpenRefundModal(true);
    setRefundData((prev) => ({
      ...prev,
      status: data?.status,
      request_id: data?.id,
    }));
  };

  const initiateRefundProcess = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await axiosPrivate.put(
        `admin/orders/cancellation-requests/initiate-refund?request_id=${refundData?.request_id}`,
        refundData
      );
      if (response?.data?.status === true) {
        setOpenRefundModal(false);
        getOrdersCancellationList();
        toast.success(response?.data?.data.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        toast.error(error?.response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setOpenRefundModal(false);
      } else {
        let errorMessage =
          "An error occurred while processing the refund for the order!";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setOpenRefundModal(false);
      }
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    getOrdersCancellationList();
  }, []);

  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle className="fs-6">
            APPROVED ORDER CANCELLATION REQUESTS
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          <DataTable
            columns={updatedColumns}
            data={cancellationRequests}
            className="mt-3"
            highlightOnHover
          />
        </CCardBody>
      </CCard>
      {isDialogOpen ? (
        <OrderDetailsModal
          open={isDialogOpen}
          id={orderId}
          setIsDialogOpen={setIsDialogOpen}
        />
      ) : null}

      {openRefundModal ? (
        <CModal
          alignment="center"
          visible={openRefundModal}
          onClose={() => {
            setOpenRefundModal(false);
          }}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CForm onSubmit={initiateRefundProcess}>
            <CModalBody>
              <div className="text-center">
                <AiOutlineExclamationCircle
                  size={100}
                  style={{ color: "orange" }}
                />
              </div>
              <div className="text-center">
                <h3>Are you sure ?</h3>
              </div>
              <div className="text-center">
                you want to proceed with the refund for this order request!
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton type="submit" color="success">
                {btnLoading ? (
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <CSpinner size="sm" /> {" Loading..."}
                  </span>
                ) : (
                  <span>CONFIRM</span>
                )}
              </CButton>
              <CButton
                color="warning"
                onClick={() => setOpenRefundModal(false)}
              >
                CANCEL
              </CButton>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </>
  );
};

export default ApprovedOCReq;
