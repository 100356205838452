import React from "react";
import { CNavGroup, CNavItem } from "@coreui/react";
import { BiCoinStack } from "react-icons/bi";
import { FaRegDotCircle, FaBorderAll } from "react-icons/fa";
import { MdDashboardCustomize } from "react-icons/md";

const _nav = [
  {
    component: CNavItem,
    name: "DASHBOARD",
    to: "/dashboard",
    icon: (
      <MdDashboardCustomize
        size={18}
        style={{
          margin: "0 10px 0 15px",
        }}
      />
    ),
  },

  {
    component: CNavGroup,
    name: "MASTER",
    to: "/master",
    icon: (
      <BiCoinStack
        size={18}
        style={{
          margin: "0 10px 0 15px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "CUSTOMERS",
        to: "/master/customers",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "BLOGS",
        to: "/master/blogs",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },
  {
    component: CNavGroup,
    name: "ORDERS",
    to: "/orders",
    icon: (
      <FaBorderAll
        size={18}
        style={{
          margin: "0 10px 0 15px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ORDERS LIST",
        to: "/orders/orders-list",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "CANCELLATION REQUESTS",
        to: "/orders/cancellation-requests",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },
];

export default _nav;
