import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CCol, CRow, CWidgetStatsF } from "@coreui/react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { IoIosPeople } from "react-icons/io";
import { FaBloggerB, FaBorderAll } from "react-icons/fa6";

const Dashboard = () => {
  const [customersCount, setCustomersCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [blogsCount, setBlogsCount] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const getCustomersList = async () => {
    try {
      const response = await axiosPrivate.get(
        `customers/list?start_date=&end_date=&page=1&search=&limit=`
      );

      if (response?.data?.status === true) {
        setCustomersCount(response?.data?.data?.list?.length);
      } else {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No orders records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching customers list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getOrdersList = async () => {
    try {
      const response = await axiosPrivate.get(
        `admin/orders/get?start_date=01-06-2023&end_date=21-10-2023&page=1&limit=&status=&order_no=`
      );

      if (response?.data?.status === true) {
        setOrdersCount(response?.data?.data?.list?.length);
      } else {
        toast.error("No orders list found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No orders records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching orders list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getBlogsList = async () => {
    try {
      const response = await axiosPrivate.get(
        `cms/blogs/get?page=1&limit=10&search=`
      );

      if (response?.data?.status === true) {
        setBlogsCount(response.data.data.list.length);
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No blog records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching blogs list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    getCustomersList();
    getOrdersList();
    getBlogsList();
  }, []);

  return (
    <div className="container">
      <CRow>
        <CCol xs={3}>
          <CWidgetStatsF
            className="mb-3 cursor-pointer"
            color="primary"
            icon={<IoIosPeople size={25} />}
            title="Curstomers"
            value={customersCount}
            onClick={() => navigate("/master/customers")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol xs={3}>
          <CWidgetStatsF
            className="mb-3 cursor-pointer"
            color="info"
            icon={<FaBloggerB size={25} />}
            title="Blogs"
            value={blogsCount}
            onClick={() => navigate("/master/blogs")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol xs={3}>
          <CWidgetStatsF
            className="mb-3 cursor-pointer"
            color="warning"
            icon={<FaBorderAll size={25} />}
            title="Orders"
            value={ordersCount}
            onClick={() => navigate("/orders/orders-list")}
            style={{ cursor: "pointer" }}
          />
        </CCol>
      </CRow>
    </div>
  );
};

export default Dashboard;
