import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CContainer,
  CForm,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import ApprovedOCReq from "./ApprovedOCReq";
import RejectedOCReq from "./RejectedOCReq";
import OrderDetailsModal from "../../components/OrderDetailsModal";
import { GiConfirmed } from "react-icons/gi";

const OrderCancellation = () => {
  const axiosPrivate = useAxiosPrivate();
  const [cancellationRequests, setCancellationRequests] = useState([]);
  const [openApproveStatusModal, setopenApproveStatusModal] = useState(false);
  const [approvedData, setApproveddData] = useState({
    request_id: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openRejectStatusModal, setOpenRejectStatusModal] = useState(false);
  const [rejectedData, setRejectedData] = useState({
    request_id: "",
    status: "",
    remark: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("PENDING");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div className="fs-7">{index + 1}</div>,
      width: "50px",
    },
    {
      name: "INVOICE NO.",
      cell: (row) => (
        <a
          className="fs-7 text-info"
          style={{ cursor: "pointer" }}
          onClick={() => onViewDetails(row)}
        >
          {row.invoice_no}
        </a>
      ),
      maxWidth: "150px",
    },
    {
      name: "CUSTOMER NAME",
      cell: (row) => <div className="fs-7">{row.customer_name}</div>,
      maxWidth: "200px",
    },
    {
      name: "PHONE",
      cell: (row) => <div className="fs-7">{row.customer_contact}</div>,
      maxWidth: "200px",
    },
    {
      name: "REASON",
      cell: (row) => <div className="fs-7">{row.reason}</div>,
      maxWidth: "150px",
    },
    {
      name: "PAYMENT MODE",
      cell: (row) => (
        <div className="fs-7 text-warning">
          <CBadge className="p-2" color="warning">
            {row.payment_mode}
          </CBadge>
        </div>
      ),
      center: true,
      minWidth: "160px",
    },
    {
      name: "ACTIONS",
      right: true,
      cell: (row) => (
        <div style={{ cursor: "pointer" }} className="d-flex gap-2">
          <CButton
            size="sm"
            color="success"
            onClick={() => handleOpenApprovalModal(row)}
          >
            Approve
          </CButton>
          <CButton
            size="sm"
            color="danger"
            onClick={() => handleRequestRejectModal(row)}
          >
            Reject
          </CButton>
        </div>
      ),
      width: "170px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const onViewDetails = (row) => {
    setIsDialogOpen(true);
    setOrderId(row?.order_id);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "PENDING") {
      getOrdersCancellationList();
    }
  };

  const getOrdersCancellationList = async () => {
    try {
      const response = await axiosPrivate.get(
        `admin/orders/cancellation-requests?type=PENDING`
      );

      if (response?.data?.status === true) {
        setCancellationRequests(response?.data?.data?.requests);
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        console.log(error?.response?.data?.data?.message);
      } else {
        let errorMessage =
          "An error occurred while fetching order cancellation request list.";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenApprovalModal = (data) => {
    setopenApproveStatusModal(true);
    setApproveddData((prevState) => ({
      ...prevState,
      request_id: data.id,
      status: "APPROVED",
    }));
  };

  const approveRequestStatusSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axiosPrivate.put(
        `admin/orders/cancellation-requests/update-status`,
        approvedData
      );

      if (response.data.status === true) {
        setopenApproveStatusModal(false);
        getOrdersCancellationList();
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        console.log(error?.response?.data?.data?.message);
      } else {
        let errorMessage =
          "An error occurred while processing the order cancellation request";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestRejectModal = (data) => {
    setOpenRejectStatusModal(true);
    setRejectedData((prevState) => ({
      ...prevState,
      request_id: data.id,
      status: "REJECTED",
    }));
  };

  const rejectRequestStatusSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await axiosPrivate.put(
        `admin/orders/cancellation-requests/update-status`,
        rejectedData
      );

      if (response.data.status === true) {
        setOpenRejectStatusModal(false);
        setRejectedData((prev) => ({ ...prev, remark: "" }));
        getOrdersCancellationList();
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        console.log(error?.response?.data?.data?.message);
      } else {
        let errorMessage =
          "An error occurred while processing the order cancellation request";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    getOrdersCancellationList();
  }, []);

  const disableBtn = !rejectedData.remark;

  return (
    <CContainer>
      <div>
        <div
          className="d-flex justify-content-center align-items-center gap-2 mb-2"
          style={{ cursor: "pointer", height: "40px" }}
        >
          <div
            color="none"
            className={`w-50 ${
              activeTab === "PENDING" ? "text-secondary" : "text-dark"
            } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("PENDING")}
            style={{
              borderBottom:
                activeTab === "PENDING" ? "2px solid #bcbcbd" : "transparent",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "PENDING" ? "#e7e9eb" : "none",
            }}
          >
            PENDING
          </div>
          <div
            color="none"
            className={`w-50 ${
              activeTab === "APPROVED" ? "text-secondary" : "text-dark"
            } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("APPROVED")}
            style={{
              borderBottom:
                activeTab === "APPROVED" ? "2px solid #bcbcbd" : "transparent",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "APPROVED" ? "#e7e9eb" : "none",
            }}
          >
            APPROVED
          </div>
          <div
            color="none"
            className={`w-50 ${
              activeTab === "REJECTED" ? "text-secondary" : "text-dark"
            } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("REJECTED")}
            style={{
              borderBottom:
                activeTab === "REJECTED" ? "2px solid #bcbcbd" : "transparent",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "REJECTED" ? "#e7e9eb" : "none",
            }}
          >
            REJECTED
          </div>
        </div>
      </div>

      {activeTab === "PENDING" && (
        <CCard>
          <CCardHeader>
            <CCardTitle className="fs-6">
              PENDING ORDER CANCELLATION REQUESTS
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {activeTab === "PENDING" && (
              <DataTable
                columns={updatedColumns}
                data={cancellationRequests}
                className="mt-3"
                highlightOnHover
              />
            )}
          </CCardBody>
        </CCard>
      )}

      {activeTab === "APPROVED" && <ApprovedOCReq />}

      {activeTab === "REJECTED" && <RejectedOCReq />}

      {openApproveStatusModal ? (
        <CModal
          alignment="center"
          visible={openApproveStatusModal}
          onClose={() => setopenApproveStatusModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CForm onSubmit={approveRequestStatusSubmit}>
            <CModalBody>
              <div className="text-center m-2">
                <GiConfirmed size={100} style={{ color: "green" }} />
              </div>
              <div className="text-center">
                Are you sure you want to approve this order request ?
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton type="submit">
                {isLoading ? (
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <CSpinner size="sm" /> {" Loading..."}
                  </span>
                ) : (
                  <span>CONFIRM</span>
                )}
              </CButton>
              <CButton
                color="warning"
                onClick={() => setopenApproveStatusModal(false)}
              >
                CANCEL
              </CButton>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openRejectStatusModal ? (
        <CModal
          alignment="center"
          visible={openRejectStatusModal}
          onClose={() => {
            setOpenRejectStatusModal(false);
            setRejectedData((prev) => ({ ...prev, remark: "" }));
          }}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              REJECT ORDER REQUEST STATUS
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={rejectRequestStatusSubmit}>
            <CModalBody>
              <div className="text-center">
                Are you sure you want to reject this order request ?
              </div>

              <div className="mt-3">
                <CFormLabel>
                  Remark <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  name="remark"
                  rows={4}
                  onChange={(e) =>
                    setRejectedData((prev) => ({
                      ...prev,
                      remark: e.target.value,
                    }))
                  }
                  value={rejectedData.remark}
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton type="submit" disabled={disableBtn}>
                {btnLoading ? (
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <CSpinner size="sm" /> {" Loading..."}
                  </span>
                ) : (
                  <span>CONFIRM</span>
                )}
              </CButton>
              <CButton
                color="warning"
                onClick={() => setOpenRejectStatusModal(false)}
              >
                CANCEL
              </CButton>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isDialogOpen ? (
        <OrderDetailsModal
          open={isDialogOpen}
          id={orderId}
          setIsDialogOpen={setIsDialogOpen}
        />
      ) : null}
    </CContainer>
  );
};

export default OrderCancellation;
