import {
  CContainer,
  CHeader,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
} from "@coreui/react";
import React from "react";
import { Link } from "react-router-dom";
import AppHeaderDropDown from "./AppHeaderDropDown";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import AppBreadCrumb from "./AppBreadCrumb";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const headerStyle = {
    transition: "height 0.3s ease",
  };
  return (
    <CHeader position="sticky" className="mb-4" style={headerStyle}>
      <CContainer
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          {sidebarShow ? (
            <RiMenuFoldLine size={17} />
          ) : (
            <RiMenuUnfoldLine size={17} />
          )}
        </CHeaderToggler>

        {/* <div className="d-flex align-items-center">
          <img src="/assets/icon-2.jpg" alt="icon" style={{ height: "38px" }} />

          <div className="ms-3">
            <AppHeaderDropDown />
          </div>
        </div> */}

        {/* <CHeaderNav className="d-none d-md-flex flex-row justify-content-center align-items-center me-auto">
                    <CNavItem>
                        <Link className="p-2 text-decoration-none text-dark fs-6" to='/dashboard'>Dashboard</Link>
                        <Link className="p-2 text-decoration-none text-dark fs-6" to='/customers'>Customers</Link>
                        <Link className="p-2 text-decoration-none text-dark fs-6" to='/orders'>Orders</Link>
                    </CNavItem>
                </CHeaderNav> */}
        <div className="d-flex align-items-center">
          <CHeaderNav>
            <img
              src="/assets/icon-2.jpg"
              alt="icon"
              style={{ height: "38px" }}
            />
          </CHeaderNav>
          <CHeaderNav className="ms-3">
            <AppHeaderDropDown />
          </CHeaderNav>
        </div>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadCrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
