import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import OrderDetailsModal from "../../components/OrderDetailsModal";

const RejectedOCReq = () => {
  const axiosPrivate = useAxiosPrivate();
  const [cancellationRequests, setCancellationRequests] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <div className="fs-7">{index + 1}</div>,
      width: "50px",
    },
    {
      name: "INVOICE NO.",
      cell: (row) => (
        <a
          className="fs-7 text-info"
          style={{ cursor: "pointer" }}
          onClick={() => onViewDetails(row)}
        >
          {row.invoice_no}
        </a>
      ),
      maxWidth: "150px",
    },
    {
      name: "CUSTOMER NAME",
      cell: (row) => <div className="fs-7">{row.customer_name}</div>,
      maxWidth: "200px",
    },
    {
      name: "PHONE",
      cell: (row) => <div className="fs-7">{row.customer_contact}</div>,
      maxWidth: "200px",
    },
    {
      name: "REASON",
      cell: (row) => <div className="fs-7">{row.reason}</div>,
      maxWidth: "200px",
    },
    {
      name: "PAYMENT MODE",
      cell: (row) => (
        <div className="fs-7 text-warning">
          <CBadge className="p-2" color="warning">
            {row.payment_mode}
          </CBadge>
        </div>
      ),
      center: true,
      minWidth: "160px",
    },
    {
      name: "STATUS",
      cell: (row) => (
        <div className="fs-7 text-warning">
          <CBadge className="p-2" color="danger">
            {row.status}
          </CBadge>
        </div>
      ),
      right: true,
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const getOrdersCancellationList = async () => {
    try {
      const response = await axiosPrivate.get(
        `admin/orders/cancellation-requests?type=REJECTED`
      );

      if (response?.data?.status === true) {
        setCancellationRequests(response?.data?.data?.requests);
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        console.log(error?.response?.data?.data?.message);
      } else {
        let errorMessage =
          "An error occurred while fetching order cancellation request list.";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const onViewDetails = (row) => {
    setIsDialogOpen(true);
    setOrderId(row?.order_id);
  };

  useEffect(() => {
    getOrdersCancellationList();
  }, []);
  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle className="fs-6">
            APPROVED ORDER CANCELLATION REQUESTS
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          <DataTable
            columns={updatedColumns}
            data={cancellationRequests}
            className="mt-3"
            highlightOnHover
          />
        </CCardBody>
      </CCard>
      {isDialogOpen ? (
        <OrderDetailsModal
          open={isDialogOpen}
          id={orderId}
          setIsDialogOpen={setIsDialogOpen}
        />
      ) : null}
    </>
  );
};

export default RejectedOCReq;
