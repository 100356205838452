import { Route, Routes } from "react-router-dom";
import AppLayout from "./AppLayout";
import Login from "./auth/Login";
import Dashboard from "./screens/dashboard";
import Customers from "./screens/customers";
import RequireAuth from "./components/RequireAuth";
import Blogs from "./screens/blogs";
import OrdersList from "./screens/orders/OrdersList";
import OrderCancellation from "./screens/orders/OrderCancellation";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<AppLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/master/customers" element={<Customers />} />
          <Route path="/orders/orders-list" element={<OrdersList />} />
          <Route path="/orders/cancellation-requests" element={<OrderCancellation />} />
          <Route path="/master/blogs" element={<Blogs />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
