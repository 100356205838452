import axios from "axios";
// const BASE_URL = 'https://dev.theuplife.in/'
const BASE_URL = 'https://apis.theuplife.in/'

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate =  axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json'}
})

