import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "../styles/orderDetails.css";

const OrderDetailsModal = ({ open, id, setIsDialogOpen }) => {
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState();
  const axiosPrivate = useAxiosPrivate();

  const getSingleOrderDetail = async (id) => {
    try {
      const response = await axiosPrivate.get(`admin/orders/${id}`);

      if (response.data.status === true) {
        setOrder(response.data.data.order);
        setOrderList(response.data.data.order.item_list);
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      let errorMessage = "An error occurred while fetching orders list.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const handlePrintInvoice = async () => {
    try {
      const invoiceContent = generateInvoiceContent(order);
      printInvoice(invoiceContent, `${order.invoice_no}`);
    } catch (error) {
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  function printInvoice(str, title) {
    var win = window.open("", title, "height=600, width=1000");
    win.document.write(
      "<html><head><title>" +
        title +
        "</title></head><body style='-webkit-print-color-adjust: exact; font-family: Verdana;'>"
    );
    win.document.write(str);
    win.document.write("</body></html>");
    win.document.close();
    win.focus();
    setTimeout(function () {
      win.print();
      win.close();
    }, 500);
    return;
  }

  const convertToUpperCase = (inputString) => {
    if (typeof inputString === "string") {
      return inputString.toUpperCase();
    } else {
      return inputString;
    }
  };

  const generateInvoiceContent = (order) => {
    let htmlString = "";
    htmlString += `
        <table width="100%" border="1" cellspacing="0" cellpadding="5px" style="font-size: 10px; border: 1px dotted #dfdfdf;">
                    <thead>
                        <tr>
                            <td colspan="6" style="text-align: center">
                                <img src="/assets/icon-2.jpg" width="100px" width="80px" alt="" />
                                <p style="font-size: 8px; font-weight: regular;">1/501, Oberoi Chambers Commercial, Premises Co-Op Society, New Link Road, Andheri (West), Mumbai - 400053</p>
                                <h4 style="margin-top: 0">GSTIN: 27AAFCN6344L1Z2</h4>
                            </td>
                        </tr>
                        <tr>
                            <th width="50%" colspan="3">
                                <table width="100%" cellspacing="0" cellpadding="5px" style="font-size: 10px;">
                                    <thead>
                                        <tr>
                                            <th colspan="2" style="text-align: center">ORDER DETAILS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style="text-align: left">INVOICE NO</th><td><b><i>${order?.invoice_no}</i></b></td>
                                        </tr>
                                        <tr>
                                            <th style="text-align: left">DATE TIME</th><td>${order?.date} ${order?.time}</td>
                                        </tr>
                                        <tr>
                                            <th style="text-align: left">PAY MODE</th><td>${order?.payment_mode}</td>
                                        </tr>
                                        <tr>
                                            <th style="text-align: left">ORDER STATUS</th><td>${order?.order_status}</td>
                                        </tr>
                                        <tr>
                                            <th style="text-align: left">PAY STATUS</th><td>${order?.payment_status}</td>
                                        </tr>`;

    if (order?.payment_mode === "ONLINE" && order?.payment_status === "PAID") {
      htmlString += `<tr>
                                                <th style="text-align: left">TRANS. ID</th><td>${order?.payment_details?.rpay_order_id}</td>
                                            </tr>`;
    }

    htmlString += `

        </tbody >
                                </table >
                            </th >
    <th width="50%" colspan="3">
        <table width="100%" cellspacing="0" cellpadding="5px" style="font-size: 10px;">
            <thead>
                <tr>
                    <th style="text-align: center; padding: 8px;">SHIPPING ADDRESS</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>${convertToUpperCase(order?.customer_name)}</td></tr>
                <tr><td>${order?.address_line_1}</td></tr>
                <tr><td>${order?.address_line_2}</td></tr>
                <tr><td>${convertToUpperCase(order?.locality)} - ${
      order?.pincode
    }</td></tr>
                <tr><td>${convertToUpperCase(
                  order?.city
                )}, ${convertToUpperCase(order.state)}</td></tr>
                <tr><td>+91-${order?.customer_contact}</td></tr>
                ${
                  order?.gstin != null
                    ? `<tr><td><b>GSTIN</b> ${order?.gstin}</td></tr>`
                    : ""
                }
            </tbody>
        </table>
    </th>
                        </tr >
    <tr>
        <th colspan="6" style="text-align: center">TAX INVOICE</th>
    </tr>
                    </thead >
                    <tbody>
                        <tr>
                            <th colspan="2">PRODUCT DETAIL</th>
                            <th className="text-center">QTY</th>
                            <th style="text-align: right">RATE</th>
                            <th style="text-align: right">DISC</th>
                            <th style="text-align: right">AMOUNT</th>
                        </tr>
        `;

    let sub_total = 0;
    let freebie_discount_amount = 0;

    order?.item_list.forEach((val) => {
      htmlString += `<tr key={val?.id}>
                                    <td colSpan="2">
                                        ${convertToUpperCase(
                                          val?.product_name
                                        )}<br />
                                        <small>${convertToUpperCase(
                                          val?.content_unit
                                        )}</small>
                                        <small>${convertToUpperCase(
                                          val?.uri_slug
                                        )}</small>
                                    </td>
                                    <td style="text-align: right">${
                                      val?.quantity
                                    } Nos</td>
                                     <td style="text-align: right">Rs. ${
                                       val?.is_freebie_item === 1
                                         ? parseFloat(val.rate).toFixed(2)
                                         : parseFloat(val.rate).toFixed(2)
                                     }</td>
                                    <td style="text-align: right">Rs. ${
                                      val?.is_freebie_item === 1
                                        ? parseFloat(val.offer_price).toFixed(2)
                                        : parseFloat(
                                            val.discount_amount
                                          ).toFixed(2)
                                    }</td>
                                    <td style="text-align: right">Rs. ${parseFloat(
                                      val?.grand_total
                                    ).toFixed(2)}</td>
                                </tr>`;
      sub_total += parseFloat(val?.grand_total);
      freebie_discount_amount += parseFloat(val?.freebie_discount_amount);
    });

    htmlString += `
        </tbody >
                <tfoot>
                    <tr>
                        <td colspan="5" style="text-align: right">SUB TOTAL</td>
                        <td style="text-align: right">Rs. ${parseFloat(
                          sub_total
                        ).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colspan="5" style="text-align: right">DELIVERY CHARGES</td>
                        <td style="text-align: right">FREE</td>
                    </tr>
                    
                    <tr>
                        <th colspan="5" style="text-align: right">GRAND TOTAL</th>
                        <th style="text-align: right">Rs. ${parseFloat(
                          order?.grand_total
                        ).toFixed(2)}</th>
                    </tr>
                </tfoot>
                </table >
        `;
    htmlString += `
        <div style="text-align: right;">
        <h5><i>Authorized Signature</i></h5>
        <img src="/assets/sign.jpeg" width="100px" alt="authorizedSignature" />
    </div>`;
    return htmlString;
  };

  useEffect(() => {
    getSingleOrderDetail(id);
  }, []);

  return (
    <CModal
      centered
      visible={open}
      onClose={() => setIsDialogOpen(false)}
      size="xl"
      aria-labelledby="VerticallyCenteredExample"
    >
      <CModalBody>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan="6" className="text-center">
                UPLIFE ORDER
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="3" valign="top" width="50%">
                <table className="table table-striped table-condensed">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center">
                        ORDER DETAILS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>INVOICE NO</th>
                      <td>
                        <b>
                          <i>{order?.invoice_no}</i>
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <th>DATE TIME</th>
                      <td>
                        {order?.date} / {order?.time}
                      </td>
                    </tr>
                    <tr>
                      <th>PAYMENT MODE</th>
                      <td>{order?.payment_mode}</td>
                    </tr>
                    <tr>
                      <th>ORDER STATUS</th>
                      <td>{order?.order_status}</td>
                    </tr>
                    <tr>
                      <th>PAYMENT STATUS</th>
                      <td>{order?.payment_status}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td colSpan="3" valign="top">
                <table className="table table-striped table-condensed">
                  <thead>
                    <tr>
                      <th className="text-center">SHIPPING DETAILS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{order?.customer_name}</td>
                    </tr>
                    <tr>
                      <td>{order?.address_line_1}</td>
                    </tr>
                    <tr>
                      <td>{order?.address_line_2}</td>
                    </tr>
                    <tr>
                      <td>
                        {order?.locality} - {order?.pincode}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {order?.city}, {order?.state}
                      </td>
                    </tr>
                    <tr>
                      <td>+91-{order?.customer_contact}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <th colSpan="6" className="text-center">
                ITEM LIST
              </th>
            </tr>
            <tr>
              <th>#</th>
              <th>PRODUCT DETAIL</th>
              <th className="text-center">QTY</th>
              <th className="text-right">RATE</th>
              <th className="text-right">DISC</th>
              <th className="text-right">AMOUNT</th>
            </tr>

            {orderList.map((item, index) => (
              <tr
                className={`${
                  item.id !== null && item.id === item.id ? "bg-yellow" : ""
                }`}
                key={index}
              >
                <td>{index + 1}</td>
                <td>
                  {item?.product_name}
                  <br />
                  {item?.content_unit}
                  <br />
                  <span className="text-info">{item.variant_title}</span>
                </td>
                <td className="text-center">{item.quantity} Nos</td>
                <td className="text-right">
                  Rs.{" "}
                  {item.is_freebie_item === 1
                    ? parseFloat(item.offer_price).toFixed(2)
                    : parseFloat(item.rate).toFixed(2)}
                </td>
                <td className="text-right">
                  Rs.{" "}
                  {item.is_freebie_item === 1
                    ? parseFloat(item.offer_price).toFixed(2)
                    : parseFloat(item.discount_amount).toFixed(2)}
                </td>
                <td className="text-right">
                  Rs. {parseFloat(item.grand_total).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="5" className="text-right">
                DELIVERY CHARGES
              </td>
              <td className="text-right">
                Rs. {parseFloat(order?.delivery_charges).toFixed(2)}
              </td>
            </tr>
            <tr>
              <th colSpan="5" className="text-right">
                <i> GRAND TOTAL</i>
              </th>
              <th className="text-right">
                Rs. {parseFloat(order?.grand_total).toFixed(2)}
              </th>
            </tr>
          </tfoot>
        </table>
      </CModalBody>

      <CModalFooter>
        <CButton color="secondary" onClick={() => setIsDialogOpen(false)}>
          CLOSE
        </CButton>
        <CButton color="primary" onClick={handlePrintInvoice}>
          PRINT
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default OrderDetailsModal;
