import React from 'react'
import AppHeader from './components/AppHeader'
import AppContent from './components/AppContent';
import AppSidebar from './components/AppSidebar'

import "../src/App.css"

const AppLayout = () => {
    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <AppContent />
                </div>
            </div>
        </div>
    )
}

export default AppLayout
