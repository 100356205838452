import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { format } from "date-fns";
import OrderDetailsModal from "../../components/OrderDetailsModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const getPaymentStatus = (status) => {
  if (status === "PAID") {
    return "success";
  }
  if (status === "UNPAID") {
    return "warning";
  }
  if (status === "PENDING") {
    return "warning";
  }
  return "";
};

const getOrderStatus = (status) => {
  if (status === "CONFIRMED") {
    return "success";
  }
  if (status === "CANCELLED") {
    return "danger";
  }
  return "";
};

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [numberValue, setNumberValue] = useState(1);
  const [statusValue, setStatusValue] = useState("");
  const [recordsValue, setRecordsValue] = useState(10);
  const axiosPrivate = useAxiosPrivate();

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(currentDate);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isUpdateOrderStatusModalOpen, setIsUpdateOrderStatusModalModalOpen] =
    useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleFilter = async () => {
    setLoading(true);
    try {
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const response = await axiosPrivate.get(
        `admin/orders/get?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=${numberValue}&limit=${recordsValue}&status=${statusValue}&order_no=`
      );

      if (response?.data?.status === true) {
        setOrders(response?.data?.data?.list);
        setTotalPages(response.data?.data?.pagination?.no_of_pages);
      } else {
        toast.error("No orders list found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data
      ) {
        toast.error("No orders records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching orders list.";
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row) => <div className="fs-7">{row.sr_no}</div>,
      width: "50px",
    },
    {
      name: "INV. NO.",
      cell: (row) => <div className="fs-7 text-info">{row.invoice_no}</div>,
      minWidth: "140px",
      maxWidth: "140px",
    },
    {
      name: "ACC. HOLDER NAME",
      cell: (row) => <div className="fs-7">{row.account_holder_name}</div>,
      minWidth: "140px",
      maxWidth: "200px",
    },
    {
      name: "MOBILE",
      cell: (row) => <div className="fs-7">{row.account_holder_mobile}</div>,
      minWidth: "140px",
      maxWidth: "200px",
    },
    {
      name: "GRAND TOTAL",
      cell: (row) => <div className="fs-7">Rs. {row.grand_total}</div>,
    },
    {
      name: "PAYMENT MODE",
      selector: (row) => row.payment_mode,
      cell: (row) => (
        <div className="fs-7 d-flex flex-column justify-content-center align-items-center">
          <div>{row.payment_mode}</div>
          <div>
            <CBadge
              className="p-1"
              color={getPaymentStatus(row.payment_status)}
            >
              {row.payment_status}
            </CBadge>
          </div>
        </div>
      ),
    },
    {
      name: "ORDER STATUS",
      cell: (row) => (
        <CBadge className="p-2" color={getOrderStatus(row.order_status)}>
          {row.order_status}
        </CBadge>
      ),
    },
    {
      name: "CITY",
      cell: (row) => <div className="fs-7">{row.city}</div>,
    },
    {
      name: "STATE",
      cell: (row) => <div className="fs-7">{row.state}</div>,
    },
    {
      name: "PINCODE",
      cell: (row) => <div className="fs-7">{row.pincode}</div>,
    },
    {
      name: "ACTIONS",
      right: true,
      cell: (row) => (
        <CDropdown variant="btn-group" direction="dropend">
          <CDropdownToggle color="secondary" size="sm">
            Action
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={() => onViewDetails(row)}>
              View Details
            </CDropdownItem>
            <CDropdownItem onClick={() => handleOpenUpdateModal(row)}>
              Update Order Status
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      ),
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const onViewDetails = (row) => {
    setIsDialogOpen(true);
    setOrderId(row.id);
  };

  const getOrdersList = async () => {
    try {
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const response = await axiosPrivate.get(
        `admin/orders/get?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=1&limit=10&status=&order_no=`
      );

      if (response?.data?.status === true) {
        setOrders(response?.data?.data?.list);
        setTotalPages(response?.data?.data?.pagination?.no_of_pages);
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        console.log(error?.response?.data?.data?.message);
      } else {
        let errorMessage = "An error occurred while fetching the orders list.";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handlePrevClick = () => {
    const prevPage = Math.max(numberValue - 1, 1);
    setNumberValue(prevPage);
    pagination(prevPage, recordsValue);
  };

  const handleNextClick = async () => {
    const nextPage = numberValue + 1;
    if (nextPage <= totalPages) {
      setNumberValue(nextPage);
      pagination(nextPage, parseInt(recordsValue, 10));
    } else {
      toast.error("Already on the last page", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const pagination = async (pageNumber, limit) => {
    try {
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const response = await axiosPrivate.get(
        `admin/orders/get?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=${pageNumber}&limit=${limit}&status=${statusValue}&order_no=`
      );
      if (response.data.status === true) {
        setOrders(response.data.data.list);
        setTotalPages(response.data?.data?.pagination?.no_of_pages);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data
      ) {
        toast.error("No orders records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching orders list.";
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleStartDateChange = (date) => {
    if (date) {
      setStartDate(date);
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      setEndDate(date);
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handleOpenUpdateModal = (data) => {
    setIsUpdateOrderStatusModalModalOpen(true);
    setUpdatedData({
      id: data.id,
      order_status: data.order_status,
    });
  };

  const handleOrderStatusChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateOrderStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosPrivate.put(
        `admin/orders/change-status`,
        updatedData
      );
      if (response?.data?.status === true) {
        setIsUpdateOrderStatusModalModalOpen(false);
        getOrdersList();
      }
    } catch (error) {
      if (error?.response?.data?.status === false) {
        toast.error(error?.response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while updating order's status.";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrdersList();
  }, []);

  return (
    <>
      <CContainer>
        <CCard>
          <CCardHeader>
            <CCardTitle className="fs-6">ORDERS LIST</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CRow xs={{ gutter: 3 }}>
              <CCol sm={12} md={2}>
                <CFormSelect
                  name="recordsValue"
                  value={recordsValue}
                  className="form-control"
                  onChange={(e) => setRecordsValue(e.target.value)}
                  options={[
                    { label: "10 records", value: "10" },
                    { label: "20 records", value: "20" },
                    { label: "40 records", value: "40" },
                    { label: "60 records", value: "60" },
                    { label: "80 records", value: "80" },
                    { label: "100 records", value: "100" },
                  ]}
                />
              </CCol>
              <CCol sm={12} md={2}>
                <CFormSelect
                  name="statusValue"
                  value={statusValue}
                  className="form-control"
                  onChange={(e) => setStatusValue(e.target.value)}
                  options={[
                    { value: "", label: "ALL" },
                    { label: "CONFIRMED", value: "CONFIRMED" },
                    { label: "DISPATCHED", value: "DISPATCHED" },
                    { label: "IN-TRANSIT", value: "IN-TRANSIT" },
                    { label: "DELIVERED", value: "DELIVERED" },
                    { label: "RETURNED", value: "RETURNED" },
                    { label: "CANCELLED", value: "CANCELLED" },
                  ]}
                />
              </CCol>

              <CCol sm={12} md={2} className="form-group col-md-2">
                <DatePicker
                  selected={startDate}
                  dateFormat="MM/dd/yyyy"
                  onChange={handleStartDateChange}
                  placeholderText="Start Date"
                  className="form-control"
                />
              </CCol>

              <CCol sm={12} md={2} className="form-group col-md-2">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  className="form-control"
                />
              </CCol>

              <CCol sm={12} md={3} className="col-md-2">
                <CButton
                  onClick={handleFilter}
                  className="btn w-100 btn-primary btn-sm d-flex align-items-center justify-content-center px-5 py-2"
                  color="secondary"
                >
                  {loading ? (
                    <span className="d-flex align-items-center justify-content-center gap-2">
                      <CSpinner size="sm" /> {" Loading..."}
                    </span>
                  ) : (
                    <span>
                      <FaArrowRight size={15} style={{ marginRight: "5px" }} />{" "}
                      SHOW
                    </span>
                  )}
                </CButton>
              </CCol>
            </CRow>

            <div className="d-flex justify-content-end mt-4">
              <div>
                <CButton
                  disabled={numberValue === 1}
                  onClick={handlePrevClick}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  {" "}
                  <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
                </CButton>
              </div>
              <div className="border rounded text-center p-1">
                Page No.{numberValue}
              </div>
              <div>
                <CButton
                  onClick={handleNextClick}
                  disabled={numberValue >= totalPages}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  {" "}
                  NEXT
                  <FaArrowRight style={{ marginLeft: "5px" }} />
                </CButton>
              </div>
            </div>

            <DataTable
              columns={updatedColumns}
              data={orders}
              className="mt-3"
              highlightOnHover
            />
          </CCardBody>
          <CCardFooter>
            <div className="d-flex justify-content-end mt-1">
              <div>
                <CButton
                  disabled={numberValue === 1}
                  onClick={handlePrevClick}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  {" "}
                  <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
                </CButton>
              </div>
              <div className="border rounded text-center p-1">
                Page No.{numberValue}
              </div>
              <div>
                <CButton
                  onClick={handleNextClick}
                  disabled={numberValue >= totalPages}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  {" "}
                  NEXT
                  <FaArrowRight style={{ marginLeft: "5px" }} />
                </CButton>
              </div>
            </div>
          </CCardFooter>
        </CCard>
      </CContainer>

      {isDialogOpen ? (
        <OrderDetailsModal
          open={isDialogOpen}
          id={orderId}
          setIsDialogOpen={setIsDialogOpen}
        />
      ) : null}

      {isUpdateOrderStatusModalOpen ? (
        <CModal
          alignment="center"
          visible={isUpdateOrderStatusModalOpen}
          onClose={() => setIsUpdateOrderStatusModalModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE ORDER STATUS
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={updateOrderStatus}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel>
                  Selct Status <span className="text-danger">*</span>
                </CFormLabel>
                <CFormSelect
                  name="order_status"
                  onChange={handleOrderStatusChange}
                  value={updatedData.order_status}
                >
                  <option value="">Select Status</option>
                  <option value="CONFIRMED">CONFIRMED</option>
                  <option value="CANCELLED">CANCELLED</option>
                </CFormSelect>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton type="submit">
                {isLoading ? (
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <CSpinner size="sm" /> {" Loading..."}
                  </span>
                ) : (
                  <span>SAVE CHANGES</span>
                )}
              </CButton>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </>
  );
};

export default OrdersList;
